<ng-template [ngIf]="mode === 'edit'" [ngIfElse]="viewMode">
  <form nz-form [formGroup]="form">
    <table>
      <thead>
        <tr>
          <th class="check-list-input__point">№ пп</th>
          <th class="check-list-input__select">Тип</th>
          <th class="check-list-input__content">Содержимое работы</th>
        </tr>
      </thead>
      <tbody formArrayName="checkPoints">
        <ng-template
          ngFor
          let-ctrl
          let-index="index"
          [ngForOf]="form.controls.checkPoints.controls"
          [ngForTrackBy]="trackByFn"
        >
          <tr [formGroupName]="index">
            <td class="check-list-input__point">
              {{ index + 1
              }}
              <button nz-button nzType="text" nzDanger>
                <span nz-icon nzType="delete" nzTheme="outline" title="Удалить пукт" (click)="removeItem(index)"></span>
              </button>
            </td>
            <td class="check-list-input__select">
                <nz-select formControlName="value">
                  <nz-option
                    *ngFor="let item of checkListItemType"
                    [nzLabel]="item.label"
                    [nzValue]="item.value"
                  ></nz-option>
              </nz-select>
            </td>
            <td class="check-list-input__content">
                <textarea nz-input formControlName="label" [nzAutosize]="{ minRows: 1, maxRows: 5 }"></textarea>
            </td>
          </tr>
        </ng-template>
        <tr>
          <td class="check-list-input__add-point-cell" colspan="3">
            <button nz-button nz-dropdown nzType="primary" title="Добавить пункт" [nzDropdownMenu]="menu">
              <span nz-icon nzType="plus-circle" nzTheme="outline"></span> Добавить пункт
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</ng-template>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li *ngFor="let item of checkListItemType" nz-menu-item (click)="addItem(item.value)">{{ item.label }}</li>
  </ul>
</nz-dropdown-menu>

<ng-template #viewMode>
  <form nz-form [formGroup]="form">
    <ng-template ngIf="checkPoints?.length">
      <nz-progress nzStrokeLinecap="round" [nzPercent]="progress"></nz-progress>
      <div class="check-list-input__list" formArrayName="checkPoints">
        <ng-template
          ngFor
          let-ctrl
          let-index="index"
          [ngForOf]="form.controls.checkPoints.controls"
          [ngForTrackBy]="trackByIndex"
        >
          <ng-container [formGroupName]="index">
            <label *ngIf="ctrl.get('type')?.value ===  dynamicAttrTypes.DynamicBoolean" class="check-list-input__list-item" nz-checkbox formControlName="value">
              {{ ctrl.get('label')?.value }}
            </label>
            <label *ngIf="ctrl.get('type')?.value ===  dynamicAttrTypes.DynamicLabel" class="check-list-input__list-item"
            [attr.data-header-level]="ctrl.get('value')?.value"
            >
              {{ ctrl.get('label')?.value }}
            </label>
          </ng-container>
        </ng-template>
      </div>
    </ng-template>
  </form>
</ng-template>
