import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, forwardRef } from '@angular/core';
import {
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzProgressModule } from 'ng-zorro-antd/progress';

import { DynamicAttr, DynamicAttrTypes } from '@api/types';
import { JsonFormControlsGroup } from '@app/shared/components/dynamic-form/types/dynamic-form-types';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CheckListItemType } from './consts';

const CompletePercents = 100;

type CheckListItemValue = (typeof CheckListItemType)[number]['value'];

@UntilDestroy()
@Component({
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NzIconModule,
        NzButtonModule,
        NzInputModule,
        NzProgressModule,
        NzCheckboxModule,
        NzDropDownModule,
        NzSelectModule,
    ],
    selector: 'un-check-list-input',
    templateUrl: './check-list-input.component.html',
    styleUrls: ['./check-list-input.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckListInputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => CheckListInputComponent),
        },
    ],
})
export class CheckListInputComponent implements ControlValueAccessor, OnInit {
    @Input()
    mode: 'view' | 'edit' = 'view';

    private readonly checkPoints = this.fb.array<JsonFormControlsGroup>([]);
    readonly form = this.fb.group({
        checkPoints: this.checkPoints,
    });

    private onChange?: (jsonForm: DynamicAttr[]) => void;
    private onToched?: () => void;

    readonly checkListItemType = CheckListItemType;
    readonly dynamicAttrTypes = DynamicAttrTypes;

    progress = 0;

    get maxListItemIndex(): number {
        return this.checkPoints.value.reduce((acc, item) => Math.max(acc, +(item.name?.split('#')?.[1] ?? 0)), 0);
    }

    constructor(private readonly fb: FormBuilder, private readonly cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
            this.onChange?.(val.checkPoints as DynamicAttr[]);

            const checkPointsLength = val.checkPoints?.filter(
                (item) => item.type === DynamicAttrTypes.DynamicBoolean,
            ).length;

            if (checkPointsLength) {
                const weight = Math.ceil(CompletePercents / checkPointsLength);

                this.progress =
                    val.checkPoints?.reduce(
                        (sum, item) => sum + (item.type === DynamicAttrTypes.DynamicBoolean && item.value ? weight : 0),
                        0,
                    ) ?? 0;
            } else {
                this.progress = 0;
            }
        });
    }

    registerOnChange(fn: (jsonForm: DynamicAttr[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onToched = fn;
    }

    writeValue(checkPoints: DynamicAttr[] | null): void {
        if (!Array.isArray(checkPoints)) {
            // eslint-disable-next-line no-console
            console.warn('incorrect checkList', checkPoints);

            return;
        }

        const checkList = checkPoints?.filter(
            (item) =>
                (item.type === DynamicAttrTypes.DynamicBoolean || item.type === DynamicAttrTypes.DynamicLabel) &&
                'name' in item &&
                'label' in item,
        );

        const dataL = checkList?.length ?? 0;
        let formL = NaN;

        do {
            formL = this.checkPoints.length;

            if (dataL > formL) {
                this.addItem(false);
            } else if (dataL < formL) {
                this.checkPoints.removeAt(-1);
            }
        } while (dataL !== formL);

        this.checkPoints.patchValue(checkList ?? []);
        this.cdr.markForCheck();
    }

    validate(): ValidationErrors | null {
        return this.form.invalid ? { checkList: 'required' } : null;
    }

    addItem(itemValue: CheckListItemValue) {
        const isHeader = typeof itemValue !== 'boolean';
        const name = `checkListItem#${this.maxListItemIndex + 1}`;

        const form: JsonFormControlsGroup = this.fb.group({
            name: new FormControl<string>(name),
            label: new FormControl<string>('', Validators.required),
            value: new FormControl<boolean | string | number>(itemValue),
            type: new FormControl<DynamicAttrTypes>(
                isHeader ? DynamicAttrTypes.DynamicLabel : DynamicAttrTypes.DynamicBoolean,
            ),
            required: new FormControl<boolean>(false),
            editable: new FormControl<boolean>(true),
        });

        this.onToched?.();
        this.checkPoints.push(form);
    }

    removeItem(idx: number) {
        this.checkPoints.removeAt(idx);
        this.onToched?.();
    }

    trackByIndex(index: number): number {
        return index;
    }

    trackByFn(index: number, item: JsonFormControlsGroup) {
        return item;
    }
}
