<div class="sidebar-logo">
  <a [routerLink]="'/'">
    <span nz-icon nzType="icons/menu:logo"></span>
  </a>
</div>
<ul>
  <ng-template ngFor let-item [ngForOf]="items | async" [ngForTrackBy]="trackBy">
    <li unTooltip unTooltipPosition="right" [unTooltipTitle]="item.path === currentRoute ? '' : item.name">
      <ng-template [ngIf]="isExternal(item.path)" [ngIfElse]="internal">
        <a target="_blank" [href]="item.path">
          <span nz-icon [nzType]="item.icon"></span>
        </a>
      </ng-template>
      <ng-template #internal>
        <a routerLinkActive="active" [routerLink]="['/', item.path]">
          <span nz-icon [nzType]="item.icon"></span>
        </a>
      </ng-template>
    </li>
  </ng-template>
</ul>
<span class="version">{{ version }}</span>
