<div class="job" nz-form nzLayout="vertical" [formGroup]="form">
  <div class="job__left">
    <nz-form-item *ngIf="isNew">
      <nz-form-label>Шаблон работы</nz-form-label>
      <nz-form-control>
        <nz-select
          nzPlaceHolder="Шаблон работы"
          [formControl]="jobTemplateCtrl"
          [nzOptions]="(templates$ | async) ?? []"
        >
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>Название новой работы</nz-form-label>
      <nz-form-control>
        <input nz-input formControlName="name" placeholder="Название новой работы" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Описание работы</nz-form-label>
      <nz-form-control>
        <nz-textarea-count [nzMaxCharacterCount]="300">
          <textarea
            class="job-new__editor"
            rows="4"
            formControlName="description"
            placeholder="Добавьте описание работы и требуемых действий для ее выполнения"
            nz-input
          ></textarea>
        </nz-textarea-count>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <div class="job-new__additional-title">Вложения</div>
      <div class="job-new__additional-files">
        <un-file-uploader-button formControlName="attachmentIds" maxFiles="6"></un-file-uploader-button>
      </div>
    </nz-form-item>

    <nz-divider></nz-divider>

    <ng-container *ngIf="newJobType === 'regulation'">
      <p class="form__title">Условие появления</p>
      <label nz-checkbox formControlName="autoToWork"> Автоматическое отправки заявки в работу </label>
      <un-schedule-editor formControlName="schedule"></un-schedule-editor>
    </ng-container>

    <ng-template [ngIf]="hasCheckList">
      <nz-form-item>
        <nz-form-split>
          <span nz-icon nzType="check-square" nzTheme="outline"></span> Чек-лист
          <un-check-list-input formControlName="checkList" mode="view"></un-check-list-input>
        </nz-form-split>
      </nz-form-item>
    </ng-template>

    <ng-template #comments [ngIf]="!isNew">
      <un-job-comments [jobId]="jobData?.id"></un-job-comments>
    </ng-template>
  </div>

  <div class="job__right">
    <nz-form-item *ngIf="isNew || jobData?.uniod?.name">
      <nz-form-label>Юниод</nz-form-label>
      <nz-form-control>
        <un-async-select
          *ngIf="isNew && !uniod; else uniodName"
          formControlName="uniodId"
          [unShowSearch]="false"
          [unOptionsSrc]="fetchUniodOptions"
        ></un-async-select>
        <ng-template #uniodName>
          <un-image-view class="job__uniod-model-img" [imageId]="fileSrc"></un-image-view>
          <nz-form-text>{{ uniod?.name ?? jobData?.uniod?.name ?? jobData?.name ?? '-' }}</nz-form-text>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="!uniod && (isNew || jobData?.groupUniods?.name)">
      <nz-form-label>Группа юниодов</nz-form-label>
      <nz-form-control>
        <un-async-select
          *ngIf="isNew; else uniodGroup"
          formControlName="groupUniodsId"
          [unOptionsSrc]="fetchGroupOptions"
          [unShowSearch]="true"
          [unAllowClear]="true"
        ></un-async-select>
        <ng-template #uniodGroup>
          <nz-form-text>{{ jobData?.groupUniods?.name ?? '-' }}</nz-form-text>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="!isNew">
      <nz-form-label>Статус</nz-form-label>
      <nz-form-control>
        <nz-select formControlName="statusId" [nzOptions]="(statuses$ | async) ?? []"></nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Плановая дата старта</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="startAtErrorTpl">
        <nz-date-picker formControlName="startAt" nzFormat="dd.MM.yyyy"></nz-date-picker>
        <ng-template #startAtErrorTpl let-control>
          <ng-container *ngIf="control.hasError('date')">Дата старта не может быть позже даты выполнения</ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Срок выполнения</nz-form-label>
      <nz-form-control nzHasFeedback [nzErrorTip]="deadlineErrorTpl">
        <nz-date-picker formControlName="deadline" nzFormat="dd.MM.yyyy"></nz-date-picker>
        <ng-template #deadlineErrorTpl let-control>
          <ng-container *ngIf="control.hasError('date')">Срок выполнения не может быть раньше даты старта</ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="!isNew">
      <nz-form-label>Дата завершения</nz-form-label>
      <nz-form-control>
        <nz-date-picker formControlName="closedAt" nzFormat="dd.MM.yyyy"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Ответственный</nz-form-label>
      <nz-form-control>
        <div class="job__user">
          <nz-avatar
            *ngIf="jobData?.uniod?.responsible || jobData?.responsible"
            [nzGap]="4"
            [nzText]="jobData?.responsible || jobData?.uniod?.responsible | unInitials"
            [nzSize]="20"
          ></nz-avatar>
          <un-async-select
            class="job__user-select"
            formControlName="responsibleId"
            [unOptionsSrc]="fetchResponsibleOptions"
            [unAllowClear]="true"
            [unShowSearch]="true"
          ></un-async-select>
        </div>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Исполнитель</nz-form-label>
      <nz-form-control>
        <div class="job__user">
          <nz-avatar
            *ngIf="jobData?.performer"
            [nzGap]="4"
            [nzText]="jobData?.performer | unInitials"
            [nzSize]="20"
          ></nz-avatar>
          <un-async-select
            class="job__user-select"
            formControlName="performerId"
            [unOptionsSrc]="fetchPerformerOptions"
            [unAllowClear]="true"
            [unShowSearch]="true"
          ></un-async-select>
        </div>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Сервисная компания</nz-form-label>
      <nz-form-control>
        <nz-select formControlName="performerCompanyId" [nzOptions]="(companyList$ | async) ?? []"></nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>
