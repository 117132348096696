import { NgStyle } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'un-tooltip-component',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle],
})
export class TooltipComponent {
    @Input() text = '';
    @Input() styles: Record<string, string | number> = {};
}
