/* eslint-disable @angular-eslint/template/no-inline-styles */
import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { Directive, ElementRef, HostListener, Input, OnDestroy, TemplateRef } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from '../components/tooltip/tooltip.component';

@Directive({
    selector: '[unTooltip]',
    standalone: true,
})
export class TooltipDirective implements OnDestroy {
    @Input() unTooltipTitle = '';
    @Input() unTooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
    @Input() unTooltipStyles: Record<string, string | number> = {};
    @Input() unTooltipTemplate?: TemplateRef<unknown>;

    private overlayRef: OverlayRef | null = null;

    constructor(
        private readonly overlay: Overlay,
        private readonly positionBuilder: OverlayPositionBuilder,
        private readonly elementRef: ElementRef,
    ) {}

    ngOnDestroy(): void {
        this.hide();
    }

    @HostListener('mouseenter')
    show() {
        if (!this.unTooltipTitle) return;

        let positions: ConnectedPosition[] = [];

        switch (this.unTooltipPosition) {
            case 'top':
                positions = [
                    {
                        originX: 'center',
                        originY: 'top',
                        overlayX: 'center',
                        overlayY: 'bottom',
                        offsetY: -8,
                    },
                ];

                break;
            case 'right':
                positions = [
                    {
                        originX: 'end',
                        originY: 'center',
                        overlayX: 'start',
                        overlayY: 'center',
                        offsetX: 8,
                    },
                ];

                break;
            case 'bottom':
                positions = [
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                        offsetY: -8,
                    },
                ];

                break;
            case 'left':
                positions = [
                    {
                        originX: 'start',
                        originY: 'center',
                        overlayX: 'end',
                        overlayY: 'center',
                        offsetX: 8,
                    },
                ];

                break;
            default:
                positions = [
                    {
                        originX: 'center',
                        originY: 'top',
                        overlayX: 'center',
                        overlayY: 'bottom',
                        offsetY: -8,
                    },
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                        offsetX: 8,
                    },
                    {
                        originX: 'start',
                        originY: 'center',
                        overlayX: 'end',
                        overlayY: 'center',
                        offsetY: -8,
                    },
                    {
                        originX: 'end',
                        originY: 'center',
                        overlayX: 'start',
                        overlayY: 'center',
                        offsetX: 8,
                    },
                ];

                break;
        }

        const positionStrategy = this.positionBuilder.flexibleConnectedTo(this.elementRef).withPositions(positions);

        this.overlayRef = this.overlay.create({ positionStrategy });

        const tooltipPortal = new ComponentPortal(TooltipComponent);
        const tooltipRef = this.overlayRef.attach(tooltipPortal);
        tooltipRef.instance.text = this.unTooltipTitle;
        tooltipRef.instance.styles = this.unTooltipStyles;

        setTimeout(() => {
            const tooltipElement = this.overlayRef?.overlayElement.querySelector('.tooltip-content') as HTMLElement;

            if (tooltipElement) {
                tooltipElement.classList.add('show');
            }
        });
    }

    @HostListener('mouseleave')
    hide() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }
}
