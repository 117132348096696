import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { combineLatest, filter, map } from 'rxjs';

import { isPublishedRoute } from '@app/core/helpers';
import { AppConfigService } from '@app/core/services/app-config.service';
import { ProfileService } from '@app/core/services/profile.service';
import { environment } from '@env/environment';
import { MenuItems } from './consts';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'un-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent implements OnInit {
    isCollapsed = true;
    isDevelop = !environment.production;
    version = `v.${environment.version.replace('-dev', '')}`;
    currentRoute = '';

    items = combineLatest({ user: inject(ProfileService).current$, config: inject(AppConfigService).current$ }).pipe(
        map(({ user, config }) =>
            MenuItems.filter(
                (item) =>
                    isPublishedRoute(item.data) &&
                    (!item.data.configPath || config?.[item.data.configPath]) &&
                    item.data.permissions &&
                    user?.hasAnyPermission(item.data.permissions),
            ).map((item) => (item.data.configPath ? { ...item, path: config?.[item.data.configPath] } : item)),
        ),
    );

    trackBy = <T extends { readonly path?: string }>(index: number, item: T) => item.path ?? index;

    constructor(private readonly router: Router) {}

    ngOnInit(): void {
        this.currentRoute = this.router.url.split('/')[1];

        this.router.events
            .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.currentRoute = event.urlAfterRedirects.split('/')[1];
            });
    }

    isExternal(link?: string): boolean {
        return !!link?.includes('://');
    }
}
